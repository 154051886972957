/* eslint-disable react/forbid-prop-types */
import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useMemo, useRef } from 'react'

import withMemo from '../../decorators/withMemo'

import useStyles from './styles'


const PageTemplate = (props) => {
  const classes = useStyles(props)
  const { className, children, title, TitleTag, slug, customHeaderLogo } = props

  // animations
  const $animatedWrapper = useRef()

  const showTitle = useMemo(() => slug !== 'info', [slug])

  return (
    <main className={cx(className, classes.container)}>
      <div className={classes.wrapper}>
        <div
          className={cx(classes.content, { custom: !!customHeaderLogo })}
          ref={$animatedWrapper}
        >
          {showTitle && title && (
            <TitleTag className={classes.title}>{title}</TitleTag>
          )}
          {children}
          <div className={classes.bottomGradient} />
        </div>
      </div>
    </main>
  )
}

export const PressTemplatePropTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
  TitleTag: PropTypes.string,
  slug: PropTypes.string,
  customHeaderLogo: PropTypes.bool,
}

PageTemplate.propTypes = PressTemplatePropTypes

PageTemplate.defaultProps = {
  className: null,
  children: null,
  title: '',
  TitleTag: 'h1',
  slug: null,
  customHeaderLogo: false,
}

export default withMemo()(PageTemplate)
