import { createUseStyles } from 'react-jss'

import { colors } from '../../theme'
import { headerHeight } from '../../components/Header/styles'


export default createUseStyles({
  layout: {
    opacity: 0,
    paddingTop: headerHeight,
    '&.custom': {
      paddingTop: `calc(${headerHeight} + 4rem)`,
    },
  },
  header: {
    position: 'fixed',
    width: '100%',
    top: 0,
    left: 0,
    right: 0,
    color: colors.pureBlack,
    backgroundColor: colors.pureWhite,
    '&.custom': {
      height: `calc(${headerHeight} + 8rem)`,
    },
  },
  customLogo: {
    cursor: 'pointer',
    position: 'absolute!important',
    top: '0',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '26rem',
  },
})
