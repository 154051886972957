import * as PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo } from 'react'
import { graphql } from 'gatsby'
import { navigate, useIntl as useIntlSource } from 'gatsby-plugin-intl'
import { gsap, Power1 } from 'gsap'
import cx from 'classnames'

import Layout from '../../components/Layout'
import PageTemplate from '../../templates/Page'
import { withCountdown, withMemo } from '../../decorators'
import { useBlocks } from '../../hooks/useAppLayout'
import { blocksTransformer } from '../../utils/BlockUtils'
import Header from '../../components/Header'
import Icon from '../../components/Icon'
import { iconsKeys } from '../../components/Icon/Icon.assets'
import router from '../../router'
import routes from '../../router/routes'
import * as appConfiguration from '../../configuration'



import useStyles from './styles'


const Page = (props) => {
  const {
    data,
    pageContext,
    location,
  } = props
  const blocks = blocksTransformer(data.contentfulPage.content)
  const renderBlocks = useBlocks(blocks, {})
  const classes = useStyles(props)

  const { locale } = useIntlSource()

  // MEMO

  const layoutProps = useMemo(() => ({
    seo: {
      lang: pageContext?.language ?? null,
      title: data?.contentfulPage?.seo?.title ?? '',
      description: data?.contentfulPage?.seo?.description?.description ?? '',
      image: appConfiguration.app.url + data?.contentfulPage?.seo?.picture?.localFile?.childImageSharp?.fluid?.src ?? '',
      url: location?.href ?? '',
    },
  }), [data.contentfulPage.seo.description.description, data.contentfulPage.seo.picture.localFile.childImageSharp.fluid.src, data.contentfulPage.seo.title, location.href, pageContext.language])

  const pageTemplateProps = useMemo(() => ({
    title: data?.contentfulPage.title ?? '',
    slug: data?.contentfulPage.slug ?? '',
  }), [data.contentfulPage.title, data.contentfulPage.slug])

  const customHeaderLogo = useMemo(() => {
    if (data?.contentfulPage.slug === 'info') {
      switch (locale) {
        case 'en':
          return iconsKeys.LogoClonesEn
        case 'zh':
          return iconsKeys.LogoClonesCn
        default:
          return null
      }
    }
  }, [data, locale])

  // ANIMATIONS

  const enterAnimation = useCallback(() => {
    const timeline = gsap.timeline({ delay: 0 })

    const page = document.querySelector(`.${classes.layout}`)

    if (page) {
      timeline.fromTo(page, {
        opacity: 0,
      }, {
        duration: 0.5,
        opacity: 1,
        ease: Power1.easeIn,
      })
    }
  }, [classes.layout])

  const exitAnimation = useCallback((onCompleteCallback) => {
    const timeline = gsap.timeline({ onComplete: onCompleteCallback })
    const page = document.querySelector(`.${classes.layout}`)

    if (page) {
      timeline.to(page, {
        duration: 0.3,
        opacity: 0,
        ease: Power1.easeIn,
      })
    }
  }, [classes.layout])

  // HANDLERS

  const handleOnClose = useCallback(() => {
    exitAnimation(() => {
      navigate(router(routes.home.path))
    })
  }, [exitAnimation])

  const handleOnLogoClose = useCallback(() => {
    exitAnimation(() => {
      navigate(router(routes.home.path))
    })
  }, [exitAnimation])

  // EFFECT

  useEffect(() => {
    enterAnimation()
  }, [enterAnimation])

  return (
    <Layout
      className={cx(classes.layout, { custom: !!customHeaderLogo })}
      header={(
        <Header
          className={cx(classes.header, { custom: !!customHeaderLogo })}
          hasBottomGradient
          centerContent={customHeaderLogo && (
          <Icon
            icon={customHeaderLogo}
            className={classes.customLogo}
            onClick={handleOnLogoClose}
          />
          )}
          rightContent={(
            <Icon
              icon={iconsKeys.Close2}
              onClick={handleOnClose}
            />
          )}
          onLogoClick={handleOnLogoClose}
        />
      )}
      {...layoutProps}
    >
      <PageTemplate
        {...pageTemplateProps}
        customHeaderLogo={!!customHeaderLogo}
      >
        {renderBlocks}
      </PageTemplate>
    </Layout>
  )
}

Page.propTypes = {
  // eslint-disable-next-line
  location: PropTypes.object,
  // eslint-disable-next-line
  data: PropTypes.object,
  // eslint-disable-next-line
  pageContext: PropTypes.object,
}

Page.defaultProps = {
  location: null,
  data: null,
  pageContext: null,
}

export default withCountdown(withMemo()(Page))

export const query = graphql`
   query page($language: String, $slug: String) {
    contentfulPage(node_locale: { eq: $language }, slug: { eq: $slug }) {
      ...balenciagaF21Page
    }
     contentfulConfiguration(node_locale: { eq: $language }) {
       ...balenciageF21Configuration
     }
   }
`
