import { createUseStyles } from 'react-jss'

import { desktop, fonts } from '../../theme'


const stylesTitle = {
  margin: 0,
  fontSize: '1.6rem',
  textTransform: 'uppercase',
  ...fonts.UniversBoldCondensed,
}

export default createUseStyles({
  container: {
    alignSelf: 'flex-start',
    height: '100%',
  },
  wrapper: {
    paddingLeft: '4rem',
    paddingRight: '4rem',
  },
  content: {
    maxWidth: '40rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '7rem',
    paddingBottom: '8rem',
    textAlign: 'justify',
    '&.custom': {
      paddingTop: '5rem',
    },
    '& > *': {
      margin: 0,
    },
    '& > * + *': {
      marginTop: '1.5rem',
    },
    ...desktop({
      paddingTop: '13rem',
      '&.custom': {
        paddingTop: '5rem',
      },
      // maxWidth: 'min(80rem, 50vw)',
      maxWidth: '50rem',
      fallbacks: {
        maxWidth: '80rem',
      },
    }),
  },
  title: {
    ...stylesTitle,
  },
  bottomGradient: {
    position: 'fixed',
    width: '100%',
    left: 0,
    right: 0,
    height: '7.4rem',
    pointerEvents: 'none',
    bottom: 0,
    background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
  },
})
